html, body {
  background-color: #282c34;
  color: white;
}

body {
  padding-left: 2%;
  padding-right: 2%;
}

main {
  position: relative;
}

h1 {
  font-size: 1.5em;
}

input {
    padding: 5px;
    border-radius: 4px;
    font-size: 1em;
}

button {
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 1em;
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
}

.App-header img {
  max-height: 125px;
}

.intro {
  max-width: 80%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
}

.play {
  min-width: 20vmin;
  min-height: 20vmin;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.play:hover img, play:active img {
  animation: none;
}

.play img {
  animation: glowing 1.5s ease-in-out infinite alternate;
}

.progress {
  border: 1px solid #FFFFFF;
  min-height: 20px;
  height: auto;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  max-width: 700px;
  margin: 2% auto;
}

.fillProgress {
  background-color: #7cdfde;
  color: #000000;
  animation: fill 1s;
}

.audioPlayer {
  padding: 2%;
}

.songInput {
  display: flex;
  justify-content: center;
  gap: 2%;
  margin-top: 2vh;
}

.falseGuesses {
  padding: 2% 0;
  display: inline-block;
  font-size: 1.3em;
}

.close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
}

.error {
    position: absolute;
    background-color: #ebadad;
    padding: 5%;
    margin: 0 auto;
    left: 0;
    right: 0;
    font-size: 1.4em;
    font-weight: bold;
    border: 2px solid #FFFFFF;
    z-index: 999;
    animation: easeOut 3s;
    max-width: 800px;
}

.solutionLetter {
  color: red;
}

.congrats {
  font-weight: bold;
  font-size: 1.4em;
}

.success {
  position: absolute;
  background-color: #4a7f57;
  padding: 5%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 5%;
  border: 2px solid #FFFFFF;
  z-index: 999;
  box-shadow: 0 0 20px #fff;
  width: 80%;
  max-width: 800px;
}

@keyframes glowing {
  from {
    filter: drop-shadow( 0 0 0px #fff) drop-shadow( 0 0 5px #7cdfde) drop-shadow( 0 0 10px #7cdfde);
  }

  to {
    
    filter: drop-shadow( 0 0 10px #fff) drop-shadow( 0 0 15px #7cdfde) drop-shadow( 0 0 20px #7cdfde);
  }
}

@keyframes easeOut {
  from {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fill {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}